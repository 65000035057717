$total:#FB5621;
$product: #FD9602;
$marketing: #FCEA3A;
$sales: #CBDA38;
$developers: #4AAF4E;
$qa: #03BAD3;
$success: #1F94F2;

.admin-employees-list {
  grid-column: 2/3;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: sticky;
  border-radius: 10px;
  > div {
    background-color: RGBA(63, 78, 97, .9);
    border-radius: 1%;
  }
}
.employees-list-container {
  width: 100%;
  min-height: 300px;
  padding: 10px 10px;
}
.employees-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100vw;
  height: 60px;
  max-height: 60px;
  font-weight: bold;
  font-size: calc(10px + 1.2vmin);
  color: white;
  margin: 5px 0;
}
.employee-container, .candidate-container {
  cursor: pointer;
  width: 1180px;
}

.employee, .candidates {
  width: 100%;
  height: 40px;
  border: 1px solid #365073;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 45px 30px 165px 5% 17% 26% 12% 11% 11%;
  position: relative;
  z-index: 2;
  color: white;
  padding-left: 10px;
  > span {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
    white-space: nowrap;
  }
}
.employee-mobile-container {
  max-width: 100%;
  grid-column: 1/-1;
}
.employee-mobile {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 40px;
  border: 1px solid #365073;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 30px 130px auto auto auto auto;
  position: relative;
  z-index: 2;
  color: white;
  padding-left: 10px;
  margin-bottom: 10px;
  > span {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 18px;
    min-width: 17px;
    white-space: nowrap;
  }
}

.candidates {
  margin-bottom: 8px;
  grid-template-columns: 25px 220px 10% 14% 28% 15% 11%;
}

.employee-additional-info {
  width: 100%;
  min-height: 40px;
  height: auto;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: -40px;
  visibility: hidden;
  border-style: solid;
  border-color: #365073;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 5px 5px;
  transition: all .3s;
  cursor: pointer;
  background-color: lighten(#404C5F, 5%);
  z-index: 1;
  position: relative;
  padding-left: 10px;
  color: RGBA(63, 78, 97, 1.00);
  > div {
    color: rgba(255,255,255,.8);
    margin:  0 10px 0 0;
    width: auto;
    span {
      color:  rgba(255,255,255,.8);
      font-size: calc(10px + .4vmin);
    }
  }
}

.employee-container:hover > .employee-additional-info {
  visibility: visible;
  margin-top: -5px;
}

.candidates-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: calc(10px + 1.3vmin);
  margin-top: -10px;
  color: white;
}



.candidate-additional-info {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: -40px;
  visibility: hidden;
  border-style: solid;
  border-color: #365073;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 5px 5px;
  transition: all .3s;
  cursor: pointer;
  background-color: white;
  z-index: 1;
  position: relative;
  padding-left: 10px;
  color: RGBA(63, 78, 97, 1.00);
}
.candidate-container:hover > .candidate-additional-info {
  visibility: visible;
  margin-top: -5px;
}

.react-datepicker-wrapper {
  display: inline-block;
}

#employees-title {
  width: 100%;
  border-radius:5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: transparent;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    &:nth-child(1) {
      border-radius:5px 5px 0 0;
      background: #3F4C5F;
      width: 20%;
      color: rgba(0,0,0,.5);
      &:hover {
        color: rgba(0,0,0,.7);
      }
    }
    &:nth-child(2) {
      border-radius: 0 0 10px 10px;
      width: 80%;
    }
  }
}


