$total:#FB5621;
$product: #FD9602;
$marketing: #FCEA3A;
$sales: #CBDA38;
$developers: #4AAF4E;
$qa: #03BAD3;
$success: #1F94F2;

.inventory-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  border-radius: 10px;
  .inventory-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 100%;
    color: white;
    font-size: calc(10px + 1.3vmin);
    font-weight: 500;
    margin-bottom: 5px;
    position: relative;
  }
  .inventory-list{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 150px 1fr;
    color: white;
    .inventory-tabs{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .inventory-tabs-database {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-radius: 10px 0 0 0 ;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 70px;
          width: 100%;
          transition: all .5s;
          cursor: pointer;
          img {
            margin-bottom: 7px;
          }
          &:hover {
            background: rgba(255, 255, 255, .7);
            color: RGBA(62, 74, 89, 1.00);
            font-weight: 600;
          }

          &:nth-child(1) {
            border-radius: 5px 5px 0 0;
            margin-top: 5px;
          }
        }
      }
      .plus-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 50px;
        width: 100%;
        transition: all .5s;
        cursor: pointer;
        border-radius: 0 0 5px 5px;
        input {
          border-radius: 5px;
          height: 25px;
          margin: 3px;
          min-width: 80%;
          max-width: 80%;
          font-size: 12px;
        }
        &:hover {

        }
      }
    }
    .selected-tab-content{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: 600px;
      background: rgba(255,255,255,.05);
      border-radius: 0 3px 3px 0;
      padding-bottom:50px;
      max-height: 600px;
      overflow-y: auto;
      transition: 1s;
      form{
        width: 99%;
        display: none;
        justify-content: center;
        align-items: center;
        height: auto;
        border-radius: 3px;
        input,select{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: none;
          width: 100%;
          height: 40px;
          padding: 10px;
          color: RGBA(62, 74, 89, 1.00);
          font-size: inherit;
          border-radius: 5px;
          margin: 1px;
        }
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 99%;
        height: 40px;
        min-height: 40px;
        padding: 5px;
        margin: 3px;
        background-color: rgba(255,255,255,0.2);
        border-radius: 3px;
      }
      .plus-add-item {
        cursor: pointer;
        height: 30px;
        &:hover {
          height: 40px;
        }
      }
    }
  }
}

div.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 20%;
  max-width: 32px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer;
  font-size: calc(10px + .6vmin);
  margin-right: 3px;
  margin-left: 2px;

  img {
    height: 12px;
    width: auto;
    opacity: .3;
    transition: all .4s;
    &:hover {
      opacity: 1;
      height: 14px;
    }
  }
}


div.settingsButton {
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width:  30%;
  max-width: 60px;
  height: 30px;
  border-radius: 3px;
  cursor: pointer;
  opacity: .1;
  transition: all .4s;
  &:hover {
    background: rgba(255,255,255,.2);
    opacity: 1;
  }
}

.input-new-tab {
  font-size: calc(10px + .6vmin);
}

.selected-item {
  span, input, select {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
    font-size: inherit;
    border: none;
  }
  select {
    border-radius: 0;
    color: RGBA(62, 74, 89, 1.00);
    option {
      border-radius: 1px;
    }
  }
  input {
    color: RGBA(62, 74, 89, 1.00);
    margin: 1px;
    &:nth-child(1) {
      border-radius: 2px 0  0 2px;
    }
  }
  .labels {
    justify-content: center;
    font-weight: bold;
  }
  .iconsEditDelete {
    opacity: .1;
    transition: all .4s;
    &:hover {
      background: rgba(255,255,255,.15);
      cursor: pointer;
      opacity: 1;
    }
  }
}

.plus-add-item {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fa-aria.appspot.com/o/add.svg?alt=media&token=a7fbb3d1-2553-4ae6-85f2-5ed4d2ec4374");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
  background-color: rgba(255,255,255,.4);
  opacity: .5;
  transition: all .4s;
  &:hover {
    background-color: rgba(255,255,255,.2);
    opacity: .8;
    background-size: 15px 15px;
  }
}
.plusButton {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fa-aria.appspot.com/o/add.svg?alt=media&token=a7fbb3d1-2553-4ae6-85f2-5ed4d2ec4374");
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: center;
  background-color: rgba(255,255,255,.4);
  opacity: .4;
  transition: all .4s;
  &:hover {
    background-color: rgba(255,255,255,.1);
    opacity: .7;
    background-size: 70% 70%;
  }
}

.xButton {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fa-aria.appspot.com/o/cancel.svg?alt=media&token=86ba4e03-0490-429d-95ae-b2d9b71af112");
  background-repeat: no-repeat;
  background-size: 40% 50%;
  background-position: center;
  background-color: rgba(255,255,255,.4);
  opacity: .4;
  transition: all .4s;
  &:hover {
    background-color: rgba(255,255,255,.1);
    opacity: .7;
    background-size: 60% 70%;
  }
}



.bottom-info {
  position: absolute;
  bottom: 17px;
  left: 147px;
  width:1050px !important;
  background: lighten(#3D4C60,0%) !important;
  span{
    margin: 0 30px;
  }
}


