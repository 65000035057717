.modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  background-color:RGBA(109, 114, 134, .9);
  min-height:150px;
  color:white;
  border-radius:10px;
  .modal-form {
    display:flex;
    justify-content:center;
    align-items:center;
    width: 500px;
    height: auto;
    background-color: white;
    border-radius: 15px;
    color: #365073;
    padding: 30px 0;
    > form  {
      display: grid;
      grid-template-rows: repeat(10 , auto);
      min-width: 80%;
      > section {
        height: 30px;
        width: 100%;
        font-size: calc(10px + .4vmin);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
      }
      .btn {
        width: 100%;
        border: none;
        padding: 10px;
        height: auto;
        border-radius: 4px;
        display:flex;
        justify-content:center;
        align-items:center;
        color: white;
        font-weight: 600;
        cursor: pointer;
        margin-top: 20px;
        &:hover {

        }
      }
      > div {
        margin: 2px 0;
        > #workEmail {
          width: 50%;
        }
        > #lastName {
           text-transform: capitalize;
        }
        > #firstName {
          text-transform: capitalize;
        }
        > .emailExtension {
          max-width: 45%;
        }
        > input {
          width: 100%;
          padding: 10px;
          height: auto;
          font-size: calc(10px + .8vmin);
          margin: 5px 0;
          border-radius: 4px;
          border: 1px solid gray;
        }
        > label {
          margin-left: 5px;
        }
        > select {
          min-height: 30px;
          height: 40px;
          padding: 10px;
          min-width: 100%;
          margin-top: 5px;
          background-color: white;
          border: 1px solid gray;
          font-size: calc(10px + .8vmin);
          margin-bottom: 5px;
        }
      }
    }
  }
}


.modal-employees-list{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 > div{
  border: 1px solid rgba(0,0,0,.07);
  box-shadow: 1px 1px 6px 2px rgba(0,0,0,.04);
  width: 80%;
  margin: 3px;
  padding: 3px;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    span {
      margin: 1px 2px 1px 10px;
    }
  }
  .resign-btn {
    border-radius: 3px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: calc(10px + .5vmin);
    box-shadow: 0px 0px 4px 2px rgba(255,255,255,.2);
  }
}
}

.modal-resign-closeButton {
  border-radius: 3px;
  height: 25px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
}

.gmTeoB {
  top: -136px !important;
  left: 115%;
  border-radius: 15px;
  .fXdwOZ {
    border-radius: 15px;
  }
}

.hPEdlB{
  input{
    margin-top: 3px;
    padding: 10px;
    font-size: calc(10px + .8vmin);
    border: 1px solid gray;
  }
}

.fXdwOZ {
  > span {
    width: 25px;
    height: 25px;
    &::after {
      bottom: 10px !important;
    }
  }
  > span::before {

  }
}



.fXdwOZ::after {
  position: relative;
  top: -1px;
  left: 5px;
}

.eymyil::after, .eymyil::before {
  position: relative;
  top: -1px;
  left: 3px;
  margin-left: 2px;
}


.dZKAmR::after, .dZKAmR::before {
  margin-right: 2px;
  position: relative;
  top: -1px;
  left: 3px;
}




.ihoGEz::after {
  margin-right: 1px;
  position: relative;
  top: -1px;
  left: 1px;
}



