@import "./stylesheets/admin-page";
@import "./stylesheets/modal";
@import "./stylesheets/employees";
@import "./stylesheets/inventory";
@import "./stylesheets/accountas";
@import "./stylesheets/switch";
@import "stylesheets/settings";
@import url('https://fonts.googleapis.com/css?family=Lato');


html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}



a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: rgba(255,255,255,0.8);
}

/* selected link */
a:active {
  color: rgba(255,255,255,0.8);
}

.App {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}


