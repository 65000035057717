.settings-modal-page {
  height: 100vh;
  width:100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255,.9);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-modal-container {
  background: rgba(255,255,255,.8);
  z-index: 11;
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  border-radius: 5px;
  position: relative;
  padding: 40px 0;
  max-height: 800px;
  overflow-y: auto;
}

.cancelSign {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  cursor: pointer;
  color: RGBA(59, 64, 67, 1.00);
  background: RGBA(59, 64, 67, .2);
  margin: 5px;
  border-radius: 3px;
}

.settings-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}



.settings-modal-fields {
  display: grid;
  grid-template-columns: 30px 150px 150px 40px;
  grid-gap: 5px;
  height: 40px;
  margin-bottom: 5px;
  cursor: move;
  border-bottom: 1px solid rgba(255,255,255,.5);
  span {
    padding-left: 7px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: RGBA(62, 74, 89, 1.00);
  }
  input {
    cursor: pointer;
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    padding-left: 0;
    border-radius: 3px;
  }
}

.settings-fields-inputs {
  display: grid;
  grid-template-columns: 30px 150px 150px 40px;
  grid-gap: 5px;
  height: 40px;
  margin-top: 15px;
  margin-right: 2px;
  input, span {
    padding-left: 5px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 3px;
    font-size: calc(10px + .6vmin);
    color: RGBA(62, 74, 89, 1);
    &::placeholder {
      color: RGBA(62, 74, 89, .4);
    }

  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    background: rgba(255,255,255, .3);
    border-radius: 3px;
    color: RGBA(62, 74, 89, 1.00);
    padding-left: 0;
  }
}

.inventory-tabs-settings {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px 50px;
  border-right: 1px solid rgba(0,0,0,.1);
  > section {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,.5);
    margin-right: 50px;
    cursor: move;
    > span {
      font-size: 13px;
      font-weight: bold;
      color: RGBA(62, 74, 89, 1.00);
      cursor: pointer;
      margin: 10px;
    }
    > input {
      cursor: pointer;
      margin: 10px;
    }
    > div {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 5px;
      img {
        height: 24px;
        cursor: pointer;
      }
      input{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        margin: 4px;
        position: relative;
      }
    }

  }

}
