.inventory-label {
    position: relative;
    left: 128px;
    font-size: 18px;
    z-index: 2;
    color: white;
   font-weight: bold;
  cursor: pointer;
  transition: all .4s;
}

.switch {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 40px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 29px;
  width: 29px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


.slider {
  background-color: #2196F3;
}

.slider {
  box-shadow: 0 0 1px #2196F3;
}

.slider:before {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.slider.inventory:before {
  -webkit-transform: translateX(140px);
  -ms-transform: translateX(140px);
  transform: translateX(140px);
}


.slider.inventory {
  background-color: #ccc;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




.employees-label {
  position: relative;
  left: 147px;
  font-size: 18px;
  z-index: 2;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all .4s;
}

.switch-employees {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 40px;
}

.switch-employees input {
  opacity: 0;
  width: 0;
  height: 0;
}

.employees-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}


.employees-slider:before {
  position: absolute;
  content: "";
  height: 29px;
  width: 29px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


.employees-slider {
}

.employees-slider {
  box-shadow: 0 0 1px #2196F3;
}

.employees-slider:before {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}


/* Rounded sliders */
.employees-slider.round {
  border-radius: 34px;
}

.employees-slider.round:before {
  border-radius: 50%;
}


.employees-slider.employees:before {
  -webkit-transform: translateX(160px);
  -ms-transform: translateX(160px);
  transform: translateX(160px);
}


