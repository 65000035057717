$total:#FB5621;
$product: #FD9602;
$marketing: #FCEA3A;
$sales: #CBDA38;
$developers: #4AAF4E;
$qa: #03BAD3;
$success: #1F94F2;

.accounts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .accounts-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 100%;
    color: white;
    font-size: calc(10px + 1.3vmin);
    font-weight: 500;
    margin-bottom: 5px;
  }
  .accounts-list{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 200px 1fr;
    color: white;
    .accounts-tabs{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .accounts-tabs-database {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(255,255,255,.3);
        width: 100%;
        border-radius: 10px 0 0 0 ;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70px;
          width: 100%;
          transition: all .5s;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, .7);
            color: RGBA(62, 74, 89, 1.00);
            font-weight: 600;
          }

          &:nth-child(1) {
            border-radius: 10px 0 0 0;
          }
        }
      }
      .accounts-plus-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 70px;
        width: 100%;
        transition: all .5s;
        cursor: pointer;
        border-radius: 0 0 0 10px;
        input {
          border-radius: 5px;
          height: 25px;
        }
        &:hover {
          background: rgba(255,255,255,.7);
         color: RGBA(62, 74, 89, 1.00);
          font-weight: 600;
          font-size: calc(10px + 1.6vmin);
        }
      }
    }
    .accounts-selected-tab-content{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,.05);
      border-radius: 0 10px 10px 0;
      form{
        width: 98%;
        display: none;
        justify-content: center;
        align-items: center;
        height: auto;
        border-radius: 5px;
        input{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: none;
          width: 100%;
          height: 40px;
          padding: 10px;
          color: RGBA(62, 74, 89, 1.00);
          &:nth-child(1) {
            border-radius: 10px 0 0 10px;
          }
          &:nth-child(5) {
            border-radius:0 10px 10px 0;
          }
        }
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98%;
        height: 40px;
        padding: 10px;
        margin: 5px;
        background: rgba(255,255,255,0.2);
        border-radius: 10px;
      }
      .accounts-plus-add-item {
        cursor: pointer;
      }
    }
  }
}

div#addButtonAccounts {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40%;
  height: 40px;
  background: rgba(255,255,255,.4);
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
}

.input-accounts-new-tab {
  font-size: calc(10px + .6vmin);
}
