$total:#FB5621;
$product: #FD9602;
$marketing: #FCEA3A;
$sales: #CBDA38;
$developers: #4AAF4E;
$qa: #03BAD3;
$success: #1F94F2;


    .adminPage-container {
        min-height: 100vh;
        min-width: 100%;
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-rows: auto 100px auto auto auto;
        grid-template-columns: 1fr auto 1fr;
        background: darken(#3F4C5F, 5%) no-repeat center; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }


    .search-container {
        grid-column: 2/3;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        > input {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 300px;
            border-radius: 10px;
            background-color: #3F4C5F;
            background-image: url("./assets/magnifying-glass.svg");
            background-repeat: no-repeat;
            background-position: 95% 50%;
            background-size: 20px;
            border: 2px solid rgba(255, 255, 255, .05);
            padding-left: 10px;
            font-size: calc(10px + 1vmin);
            color: rgba(255, 255, 255, .8);

            &::placeholder {
                font-size: calc(10px + 1vmin);
                color: rgba(255, 255, 255, .4);
            }
        }
    }

    .employees {
        padding: 0 10px;
    }

    .admin-header {
        width: 100%;
        height: auto;
        grid-column: 2/3;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #3F4C5F;
        border-radius: 5px;
        margin: 10px 0;

        > div {
            width: 140px;
            height: auto;
            margin: 1%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 5%;
            transition: all .5s;
            color: white;
            cursor: pointer;

            .flex-column {
                font-weight: 500;
                font-size: calc(10px + 1.1vmin);

                span {
                    padding: 0 15px;
                    margin: 10px;
                    border-radius: 3px;
                    font-size: calc(10px + 1.2vmin);

                    &:hover {
                        background: rgba(255, 255, 255, .2);
                    }
                }
            }

            &:nth-child(1) {
                border: 2px solid #3F4C5F;

                &:hover {
                    background: rgba(255, 255, 255, .1);
                }
            }

            &:nth-child(2) {
                border: 2px solid #4AAF4E;

                &:hover {
                    background: rgba(255, 255, 255, .1);
                }
            }

            &:nth-child(3) {
                border: 2px solid #FCEA3A;

                &:hover {
                    background: rgba(255, 255, 255, .1);
                }
            }

            &:nth-child(4) {
                border: 2px solid #FD9602;

                &:hover {
                    background: rgba(255, 255, 255, .1);
                }
            }

            &:nth-child(5) {
                border: 2px solid #03BAD3;

                &:hover {
                    background: rgba(255, 255, 255, .1);
                }
            }

            &:nth-child(6) {
                border: 2px solid #CBDA38;

                &:hover {
                    background: rgba(255, 255, 255, .1);
                }
            }

            &:nth-child(7) {
                border: 2px solid #1F94F2;

                &:hover {
                    background: rgba(255, 255, 255, .1);
                }
            }

            > div {
                margin: 5%;
                font-size: calc(10px + 1.3vmin);
                font-family: museo-sans, sans-serif;
                font-style: normal;
            }
        }
    }


    .admin-footer {
        grid-column: 2/3;
        grid-row: 3/4;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .05;
        transition: all 1s;

        &:hover {
            opacity: .8;
            height: 120px;
        }
    }


@media screen and (max-width: 600px) {
    .adminPage-container {
        min-height: 100vh;
        min-width: 100%;
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-rows: 70px auto auto auto;
        grid-template-columns: 1fr auto 1fr;
        background: darken(#3F4C5F, 5%) no-repeat center; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
    .admin-header {
       display: none;
    }
        .search-container {
        grid-column: 1/-1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
            > input {
                min-width: 300px;
            }
    }

}





