@import url(https://fonts.googleapis.com/css?family=Lato);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.adminPage-container {
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: auto 100px auto auto auto;
  grid-template-columns: 1fr auto 1fr;
  background: #354050 no-repeat center;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */ }

.search-container {
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .search-container > input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 300px;
    border-radius: 10px;
    background-color: #3F4C5F;
    background-image: url(/static/media/magnifying-glass.2b2ad38e.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 20px;
    border: 2px solid rgba(255, 255, 255, 0.05);
    padding-left: 10px;
    font-size: calc(10px + 1vmin);
    color: rgba(255, 255, 255, 0.8); }
    .search-container > input::-webkit-input-placeholder {
      font-size: calc(10px + 1vmin);
      color: rgba(255, 255, 255, 0.4); }
    .search-container > input::-moz-placeholder {
      font-size: calc(10px + 1vmin);
      color: rgba(255, 255, 255, 0.4); }
    .search-container > input:-ms-input-placeholder {
      font-size: calc(10px + 1vmin);
      color: rgba(255, 255, 255, 0.4); }
    .search-container > input::-ms-input-placeholder {
      font-size: calc(10px + 1vmin);
      color: rgba(255, 255, 255, 0.4); }
    .search-container > input::placeholder {
      font-size: calc(10px + 1vmin);
      color: rgba(255, 255, 255, 0.4); }

.employees {
  padding: 0 10px; }

.admin-header {
  width: 100%;
  height: auto;
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3F4C5F;
  border-radius: 5px;
  margin: 10px 0; }
  .admin-header > div {
    width: 140px;
    height: auto;
    margin: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5%;
    -webkit-transition: all .5s;
    transition: all .5s;
    color: white;
    cursor: pointer; }
    .admin-header > div .flex-column {
      font-weight: 500;
      font-size: calc(10px + 1.1vmin); }
      .admin-header > div .flex-column span {
        padding: 0 15px;
        margin: 10px;
        border-radius: 3px;
        font-size: calc(10px + 1.2vmin); }
        .admin-header > div .flex-column span:hover {
          background: rgba(255, 255, 255, 0.2); }
    .admin-header > div:nth-child(1) {
      border: 2px solid #3F4C5F; }
      .admin-header > div:nth-child(1):hover {
        background: rgba(255, 255, 255, 0.1); }
    .admin-header > div:nth-child(2) {
      border: 2px solid #4AAF4E; }
      .admin-header > div:nth-child(2):hover {
        background: rgba(255, 255, 255, 0.1); }
    .admin-header > div:nth-child(3) {
      border: 2px solid #FCEA3A; }
      .admin-header > div:nth-child(3):hover {
        background: rgba(255, 255, 255, 0.1); }
    .admin-header > div:nth-child(4) {
      border: 2px solid #FD9602; }
      .admin-header > div:nth-child(4):hover {
        background: rgba(255, 255, 255, 0.1); }
    .admin-header > div:nth-child(5) {
      border: 2px solid #03BAD3; }
      .admin-header > div:nth-child(5):hover {
        background: rgba(255, 255, 255, 0.1); }
    .admin-header > div:nth-child(6) {
      border: 2px solid #CBDA38; }
      .admin-header > div:nth-child(6):hover {
        background: rgba(255, 255, 255, 0.1); }
    .admin-header > div:nth-child(7) {
      border: 2px solid #1F94F2; }
      .admin-header > div:nth-child(7):hover {
        background: rgba(255, 255, 255, 0.1); }
    .admin-header > div > div {
      margin: 5%;
      font-size: calc(10px + 1.3vmin);
      font-family: museo-sans, sans-serif;
      font-style: normal; }

.admin-footer {
  grid-column: 2/3;
  grid-row: 3/4;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .05;
  -webkit-transition: all 1s;
  transition: all 1s; }
  .admin-footer:hover {
    opacity: .8;
    height: 120px; }

@media screen and (max-width: 600px) {
  .adminPage-container {
    min-height: 100vh;
    min-width: 100%;
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: 70px auto auto auto;
    grid-template-columns: 1fr auto 1fr;
    background: #354050 no-repeat center;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */ }
  .admin-header {
    display: none; }
  .search-container {
    grid-column: 1/-1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
    .search-container > input {
      min-width: 300px; } }

.modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: RGBA(109, 114, 134, 0.9);
  min-height: 150px;
  color: white;
  border-radius: 10px; }
  .modal-container .modal-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: auto;
    background-color: white;
    border-radius: 15px;
    color: #365073;
    padding: 30px 0; }
    .modal-container .modal-form > form {
      display: grid;
      grid-template-rows: repeat(10, auto);
      min-width: 80%; }
      .modal-container .modal-form > form > section {
        height: 30px;
        width: 100%;
        font-size: calc(10px + .4vmin);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        cursor: pointer; }
      .modal-container .modal-form > form .btn {
        width: 100%;
        border: none;
        padding: 10px;
        height: auto;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
        cursor: pointer;
        margin-top: 20px; }
      .modal-container .modal-form > form > div {
        margin: 2px 0; }
        .modal-container .modal-form > form > div > #workEmail {
          width: 50%; }
        .modal-container .modal-form > form > div > #lastName {
          text-transform: capitalize; }
        .modal-container .modal-form > form > div > #firstName {
          text-transform: capitalize; }
        .modal-container .modal-form > form > div > .emailExtension {
          max-width: 45%; }
        .modal-container .modal-form > form > div > input {
          width: 100%;
          padding: 10px;
          height: auto;
          font-size: calc(10px + .8vmin);
          margin: 5px 0;
          border-radius: 4px;
          border: 1px solid gray; }
        .modal-container .modal-form > form > div > label {
          margin-left: 5px; }
        .modal-container .modal-form > form > div > select {
          min-height: 30px;
          height: 40px;
          padding: 10px;
          min-width: 100%;
          margin-top: 5px;
          background-color: white;
          border: 1px solid gray;
          font-size: calc(10px + .8vmin);
          margin-bottom: 5px; }

.modal-employees-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .modal-employees-list > div {
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.04);
    width: 80%;
    margin: 3px;
    padding: 3px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .modal-employees-list > div section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; }
      .modal-employees-list > div section span {
        margin: 1px 2px 1px 10px; }
    .modal-employees-list > div .resign-btn {
      border-radius: 3px;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      color: white;
      cursor: pointer;
      font-weight: bold;
      font-size: calc(10px + .5vmin);
      box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.2); }

.modal-resign-closeButton {
  border-radius: 3px;
  height: 25px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  cursor: pointer; }

.gmTeoB {
  top: -136px !important;
  left: 115%;
  border-radius: 15px; }
  .gmTeoB .fXdwOZ {
    border-radius: 15px; }

.hPEdlB input {
  margin-top: 3px;
  padding: 10px;
  font-size: calc(10px + .8vmin);
  border: 1px solid gray; }

.fXdwOZ > span {
  width: 25px;
  height: 25px; }
  .fXdwOZ > span::after {
    bottom: 10px !important; }

.fXdwOZ::after {
  position: relative;
  top: -1px;
  left: 5px; }

.eymyil::after, .eymyil::before {
  position: relative;
  top: -1px;
  left: 3px;
  margin-left: 2px; }

.dZKAmR::after, .dZKAmR::before {
  margin-right: 2px;
  position: relative;
  top: -1px;
  left: 3px; }

.ihoGEz::after {
  margin-right: 1px;
  position: relative;
  top: -1px;
  left: 1px; }

.admin-employees-list {
  grid-column: 2/3;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  border-radius: 10px; }
  .admin-employees-list > div {
    background-color: RGBA(63, 78, 97, 0.9);
    border-radius: 1%; }

.employees-list-container {
  width: 100%;
  min-height: 300px;
  padding: 10px 10px; }

.employees-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100vw;
  height: 60px;
  max-height: 60px;
  font-weight: bold;
  font-size: calc(10px + 1.2vmin);
  color: white;
  margin: 5px 0; }

.employee-container, .candidate-container {
  cursor: pointer;
  width: 1180px; }

.employee, .candidates {
  width: 100%;
  height: 40px;
  border: 1px solid #365073;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 45px 30px 165px 5% 17% 26% 12% 11% 11%;
  position: relative;
  z-index: 2;
  color: white;
  padding-left: 10px; }
  .employee > span, .candidates > span {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
    white-space: nowrap; }

.employee-mobile-container {
  max-width: 100%;
  grid-column: 1/-1; }

.employee-mobile {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 40px;
  border: 1px solid #365073;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 30px 130px auto auto auto auto;
  position: relative;
  z-index: 2;
  color: white;
  padding-left: 10px;
  margin-bottom: 10px; }
  .employee-mobile > span {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 18px;
    min-width: 17px;
    white-space: nowrap; }

.candidates {
  margin-bottom: 8px;
  grid-template-columns: 25px 220px 10% 14% 28% 15% 11%; }

.employee-additional-info {
  width: 100%;
  min-height: 40px;
  height: auto;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: -40px;
  visibility: hidden;
  border-style: solid;
  border-color: #365073;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 5px 5px;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  background-color: #4a586e;
  z-index: 1;
  position: relative;
  padding-left: 10px;
  color: RGBA(63, 78, 97, 1); }
  .employee-additional-info > div {
    color: rgba(255, 255, 255, 0.8);
    margin: 0 10px 0 0;
    width: auto; }
    .employee-additional-info > div span {
      color: rgba(255, 255, 255, 0.8);
      font-size: calc(10px + .4vmin); }

.employee-container:hover > .employee-additional-info {
  visibility: visible;
  margin-top: -5px; }

.candidates-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: calc(10px + 1.3vmin);
  margin-top: -10px;
  color: white; }

.candidate-additional-info {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: -40px;
  visibility: hidden;
  border-style: solid;
  border-color: #365073;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 5px 5px;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  background-color: white;
  z-index: 1;
  position: relative;
  padding-left: 10px;
  color: RGBA(63, 78, 97, 1); }

.candidate-container:hover > .candidate-additional-info {
  visibility: visible;
  margin-top: -5px; }

.react-datepicker-wrapper {
  display: inline-block; }

#employees-title {
  width: 100%;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: transparent; }
  #employees-title > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; }
    #employees-title > div:nth-child(1) {
      border-radius: 5px 5px 0 0;
      background: #3F4C5F;
      width: 20%;
      color: rgba(0, 0, 0, 0.5); }
      #employees-title > div:nth-child(1):hover {
        color: rgba(0, 0, 0, 0.7); }
    #employees-title > div:nth-child(2) {
      border-radius: 0 0 10px 10px;
      width: 80%; }

.inventory-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  border-radius: 10px; }
  .inventory-container .inventory-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 100%;
    color: white;
    font-size: calc(10px + 1.3vmin);
    font-weight: 500;
    margin-bottom: 5px;
    position: relative; }
  .inventory-container .inventory-list {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 150px 1fr;
    color: white; }
    .inventory-container .inventory-list .inventory-tabs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column; }
      .inventory-container .inventory-list .inventory-tabs .inventory-tabs-database {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-radius: 10px 0 0 0; }
        .inventory-container .inventory-list .inventory-tabs .inventory-tabs-database div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 70px;
          width: 100%;
          -webkit-transition: all .5s;
          transition: all .5s;
          cursor: pointer; }
          .inventory-container .inventory-list .inventory-tabs .inventory-tabs-database div img {
            margin-bottom: 7px; }
          .inventory-container .inventory-list .inventory-tabs .inventory-tabs-database div:hover {
            background: rgba(255, 255, 255, 0.7);
            color: RGBA(62, 74, 89, 1);
            font-weight: 600; }
          .inventory-container .inventory-list .inventory-tabs .inventory-tabs-database div:nth-child(1) {
            border-radius: 5px 5px 0 0;
            margin-top: 5px; }
      .inventory-container .inventory-list .inventory-tabs .plus-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 50px;
        width: 100%;
        -webkit-transition: all .5s;
        transition: all .5s;
        cursor: pointer;
        border-radius: 0 0 5px 5px; }
        .inventory-container .inventory-list .inventory-tabs .plus-tab input {
          border-radius: 5px;
          height: 25px;
          margin: 3px;
          min-width: 80%;
          max-width: 80%;
          font-size: 12px; }
    .inventory-container .inventory-list .selected-tab-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: 600px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 0 3px 3px 0;
      padding-bottom: 50px;
      max-height: 600px;
      overflow-y: auto;
      -webkit-transition: 1s;
      transition: 1s; }
      .inventory-container .inventory-list .selected-tab-content form {
        width: 99%;
        display: none;
        justify-content: center;
        align-items: center;
        height: auto;
        border-radius: 3px; }
        .inventory-container .inventory-list .selected-tab-content form input, .inventory-container .inventory-list .selected-tab-content form select {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: none;
          width: 100%;
          height: 40px;
          padding: 10px;
          color: RGBA(62, 74, 89, 1);
          font-size: inherit;
          border-radius: 5px;
          margin: 1px; }
      .inventory-container .inventory-list .selected-tab-content > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 99%;
        height: 40px;
        min-height: 40px;
        padding: 5px;
        margin: 3px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 3px; }
      .inventory-container .inventory-list .selected-tab-content .plus-add-item {
        cursor: pointer;
        height: 30px; }
        .inventory-container .inventory-list .selected-tab-content .plus-add-item:hover {
          height: 40px; }

div.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 20%;
  max-width: 32px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer;
  font-size: calc(10px + .6vmin);
  margin-right: 3px;
  margin-left: 2px; }
  div.addButton img {
    height: 12px;
    width: auto;
    opacity: .3;
    -webkit-transition: all .4s;
    transition: all .4s; }
    div.addButton img:hover {
      opacity: 1;
      height: 14px; }

div.settingsButton {
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 30%;
  max-width: 60px;
  height: 30px;
  border-radius: 3px;
  cursor: pointer;
  opacity: .1;
  -webkit-transition: all .4s;
  transition: all .4s; }
  div.settingsButton:hover {
    background: rgba(255, 255, 255, 0.2);
    opacity: 1; }

.input-new-tab {
  font-size: calc(10px + .6vmin); }

.selected-item span, .selected-item input, .selected-item select {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  font-size: inherit;
  border: none; }

.selected-item select {
  border-radius: 0;
  color: RGBA(62, 74, 89, 1); }
  .selected-item select option {
    border-radius: 1px; }

.selected-item input {
  color: RGBA(62, 74, 89, 1);
  margin: 1px; }
  .selected-item input:nth-child(1) {
    border-radius: 2px 0  0 2px; }

.selected-item .labels {
  justify-content: center;
  font-weight: bold; }

.selected-item .iconsEditDelete {
  opacity: .1;
  -webkit-transition: all .4s;
  transition: all .4s; }
  .selected-item .iconsEditDelete:hover {
    background: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    opacity: 1; }

.plus-add-item {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fa-aria.appspot.com/o/add.svg?alt=media&token=a7fbb3d1-2553-4ae6-85f2-5ed4d2ec4374");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: .5;
  -webkit-transition: all .4s;
  transition: all .4s; }
  .plus-add-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    opacity: .8;
    background-size: 15px 15px; }

.plusButton {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fa-aria.appspot.com/o/add.svg?alt=media&token=a7fbb3d1-2553-4ae6-85f2-5ed4d2ec4374");
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: .4;
  -webkit-transition: all .4s;
  transition: all .4s; }
  .plusButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
    opacity: .7;
    background-size: 70% 70%; }

.xButton {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/fa-aria.appspot.com/o/cancel.svg?alt=media&token=86ba4e03-0490-429d-95ae-b2d9b71af112");
  background-repeat: no-repeat;
  background-size: 40% 50%;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: .4;
  -webkit-transition: all .4s;
  transition: all .4s; }
  .xButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
    opacity: .7;
    background-size: 60% 70%; }

.bottom-info {
  position: absolute;
  bottom: 17px;
  left: 147px;
  width: 1050px !important;
  background: #3d4c60 !important; }
  .bottom-info span {
    margin: 0 30px; }

.accounts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%; }
  .accounts-container .accounts-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 100%;
    color: white;
    font-size: calc(10px + 1.3vmin);
    font-weight: 500;
    margin-bottom: 5px; }
  .accounts-container .accounts-list {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 200px 1fr;
    color: white; }
    .accounts-container .accounts-list .accounts-tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .accounts-container .accounts-list .accounts-tabs .accounts-tabs-database {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.3);
        width: 100%;
        border-radius: 10px 0 0 0; }
        .accounts-container .accounts-list .accounts-tabs .accounts-tabs-database div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70px;
          width: 100%;
          -webkit-transition: all .5s;
          transition: all .5s;
          cursor: pointer; }
          .accounts-container .accounts-list .accounts-tabs .accounts-tabs-database div:hover {
            background: rgba(255, 255, 255, 0.7);
            color: RGBA(62, 74, 89, 1);
            font-weight: 600; }
          .accounts-container .accounts-list .accounts-tabs .accounts-tabs-database div:nth-child(1) {
            border-radius: 10px 0 0 0; }
      .accounts-container .accounts-list .accounts-tabs .accounts-plus-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 70px;
        width: 100%;
        -webkit-transition: all .5s;
        transition: all .5s;
        cursor: pointer;
        border-radius: 0 0 0 10px; }
        .accounts-container .accounts-list .accounts-tabs .accounts-plus-tab input {
          border-radius: 5px;
          height: 25px; }
        .accounts-container .accounts-list .accounts-tabs .accounts-plus-tab:hover {
          background: rgba(255, 255, 255, 0.7);
          color: RGBA(62, 74, 89, 1);
          font-weight: 600;
          font-size: calc(10px + 1.6vmin); }
    .accounts-container .accounts-list .accounts-selected-tab-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 0 10px 10px 0; }
      .accounts-container .accounts-list .accounts-selected-tab-content form {
        width: 98%;
        display: none;
        justify-content: center;
        align-items: center;
        height: auto;
        border-radius: 5px; }
        .accounts-container .accounts-list .accounts-selected-tab-content form input {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: none;
          width: 100%;
          height: 40px;
          padding: 10px;
          color: RGBA(62, 74, 89, 1); }
          .accounts-container .accounts-list .accounts-selected-tab-content form input:nth-child(1) {
            border-radius: 10px 0 0 10px; }
          .accounts-container .accounts-list .accounts-selected-tab-content form input:nth-child(5) {
            border-radius: 0 10px 10px 0; }
      .accounts-container .accounts-list .accounts-selected-tab-content > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 98%;
        height: 40px;
        padding: 10px;
        margin: 5px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px; }
      .accounts-container .accounts-list .accounts-selected-tab-content .accounts-plus-add-item {
        cursor: pointer; }

div#addButtonAccounts {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40%;
  height: 40px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  margin: 5px;
  cursor: pointer; }

.input-accounts-new-tab {
  font-size: calc(10px + .6vmin); }

.inventory-label {
  position: relative;
  left: 128px;
  font-size: 18px;
  z-index: 2;
  color: white;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all .4s;
  transition: all .4s; }

.switch {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 40px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 29px;
  width: 29px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

.slider {
  background-color: #2196F3; }

.slider {
  box-shadow: 0 0 1px #2196F3; }

.slider:before {
  -webkit-transform: translateX(0px);
  transform: translateX(0px); }

.slider.inventory:before {
  -webkit-transform: translateX(140px);
  transform: translateX(140px); }

.slider.inventory {
  background-color: #ccc; }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.employees-label {
  position: relative;
  left: 147px;
  font-size: 18px;
  z-index: 2;
  color: white;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all .4s;
  transition: all .4s; }

.switch-employees {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 40px; }

.switch-employees input {
  opacity: 0;
  width: 0;
  height: 0; }

.employees-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s; }

.employees-slider:before {
  position: absolute;
  content: "";
  height: 29px;
  width: 29px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

.employees-slider {
  box-shadow: 0 0 1px #2196F3; }

.employees-slider:before {
  -webkit-transform: translateX(0px);
  transform: translateX(0px); }

/* Rounded sliders */
.employees-slider.round {
  border-radius: 34px; }

.employees-slider.round:before {
  border-radius: 50%; }

.employees-slider.employees:before {
  -webkit-transform: translateX(160px);
  transform: translateX(160px); }

.settings-modal-page {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center; }

.settings-modal-container {
  background: rgba(255, 255, 255, 0.8);
  z-index: 11;
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  border-radius: 5px;
  position: relative;
  padding: 40px 0;
  max-height: 800px;
  overflow-y: auto; }

.cancelSign {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: bold;
  cursor: pointer;
  color: RGBA(59, 64, 67, 1);
  background: RGBA(59, 64, 67, 0.2);
  margin: 5px;
  border-radius: 3px; }

.settings-modal-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.settings-modal-fields {
  display: grid;
  grid-template-columns: 30px 150px 150px 40px;
  grid-gap: 5px;
  height: 40px;
  margin-bottom: 5px;
  cursor: move;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .settings-modal-fields span {
    padding-left: 7px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: RGBA(62, 74, 89, 1); }
  .settings-modal-fields input {
    cursor: pointer; }
  .settings-modal-fields .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    padding-left: 0;
    border-radius: 3px; }

.settings-fields-inputs {
  display: grid;
  grid-template-columns: 30px 150px 150px 40px;
  grid-gap: 5px;
  height: 40px;
  margin-top: 15px;
  margin-right: 2px; }
  .settings-fields-inputs input, .settings-fields-inputs span {
    padding-left: 5px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 3px;
    font-size: calc(10px + .6vmin);
    color: RGBA(62, 74, 89, 1); }
    .settings-fields-inputs input::-webkit-input-placeholder, .settings-fields-inputs span::-webkit-input-placeholder {
      color: RGBA(62, 74, 89, 0.4); }
    .settings-fields-inputs input::-moz-placeholder, .settings-fields-inputs span::-moz-placeholder {
      color: RGBA(62, 74, 89, 0.4); }
    .settings-fields-inputs input:-ms-input-placeholder, .settings-fields-inputs span:-ms-input-placeholder {
      color: RGBA(62, 74, 89, 0.4); }
    .settings-fields-inputs input::-ms-input-placeholder, .settings-fields-inputs span::-ms-input-placeholder {
      color: RGBA(62, 74, 89, 0.4); }
    .settings-fields-inputs input::placeholder, .settings-fields-inputs span::placeholder {
      color: RGBA(62, 74, 89, 0.4); }
  .settings-fields-inputs .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    color: RGBA(62, 74, 89, 1);
    padding-left: 0; }

.inventory-tabs-settings {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px 50px;
  border-right: 1px solid rgba(0, 0, 0, 0.1); }
  .inventory-tabs-settings > section {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-right: 50px;
    cursor: move; }
    .inventory-tabs-settings > section > span {
      font-size: 13px;
      font-weight: bold;
      color: RGBA(62, 74, 89, 1);
      cursor: pointer;
      margin: 10px; }
    .inventory-tabs-settings > section > input {
      cursor: pointer;
      margin: 10px; }
    .inventory-tabs-settings > section > div {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 5px; }
      .inventory-tabs-settings > section > div img {
        height: 24px;
        cursor: pointer; }
      .inventory-tabs-settings > section > div input {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        margin: 4px;
        position: relative; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

a:link {
  color: white;
  text-decoration: none; }

/* visited link */
a:visited {
  color: white; }

/* mouse over link */
a:hover {
  color: rgba(255, 255, 255, 0.8); }

/* selected link */
a:active {
  color: rgba(255, 255, 255, 0.8); }

.App {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  font-style: normal; }

.flex {
  display: flex;
  justify-content: center;
  align-items: center; }

